/* HOME START */

.video-wrapper {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.video-frame {
  width: 100%;
  z-index: 5;
}

.homeVideo {
  position: absolute;
  height: 100%;
  top: 50%;
  left: 45%;
  z-index: -5;
  transform: translate(-50%, -50%);
}

/* HOME END */

/* CONTACT START */

.contactItem {
  position: relative;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-wrapper {
  padding: 10px;
  background-color: #4e9546;
  border-radius: 50%;
}

.icon-wrapper:hover {
  animation: light-up-bgColor 0.25s forwards ease-out alternate;
}

@keyframes light-up-bgColor {
  0% {
    background-color: #4e9546;
  }
  100% {
    background-color: #8dc63f;
  }
}

.icon {
  width: 30px;
  height: 30px;
  color: white;
}

.content {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  font-weight: bold;
}

.link {
  text-decoration: none;
  color: #4e9546;
}

.link:hover {
  animation: light-up-color 0.25s forwards ease-out alternate;
}

@keyframes light-up-color {
  0% {
    color: #4e9546;
  }
  100% {
    color: #8dc63f;
  }
}

/* CONTACT ENDS */
