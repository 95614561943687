body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#home {
  padding: 0 200px 0 200px;
}

@media (max-width: 1100px) {
  #home {
    padding: 0;
  }
}

.mobile-line-sale {
  display: none;
}

@media (max-width: 426px) {
  .mobile-line-sale {
    display: block;
  }
}

.normal-line-sale {
  display: block;
}

@media (max-width: 426px) {
  .normal-line-sale {
    display: none;
  }
}

.pavathi-container {
}

@media (min-width: 1441px) {
  .pavathi-container {
    flex: 0 0 auto;
    width: 38%;
  }
}

@media (min-width: 1441px) and (max-width: 2559px) {
  .pavathi-container {
    width: 50%;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video-background {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 110px;
  right: 120px;
  width: 400px;
  height: 375px;
  pointer-events: none;
}

#vidtop-content {
  top: 0;
  color: #fff;
}

.contact-h {
  padding: 0 170px 0 170px;
  overflow: hidden;
}

@media (max-width: 1100px) {
  .contact-h {
    padding: 0;
    margin: 0;
  }
}

.our-process-space {
  width: 0;
}

#email {
  font-size: 18px;
}

/* mobile */
@media (max-width: 320px) {
  .contact-h {
    padding: 0;
  }

  .our-process-space {
    height: 50px;
    width: 0;
  }

  #carousel-1 {
    height: 250px;
  }

  #carousel-item {
    height: 200px;
  }

  #support {
    flex-direction: column-reverse !important;
  }

  #email {
    font-size: 14px;
  }
}

/* mobile L */
@media (min-width: 321px) and (max-width: 425px) {
  .contact-h {
    padding: 0;
  }

  .our-process-space {
    height: 50px;
    width: 0;
  }

  #carousel-1 {
    height: 260px;
  }

  #carousel-item {
    height: 210px;
  }

  #support {
    flex-direction: column-reverse !important;
  }

  #email {
    font-size: 16px;
  }
}

/* tab */
@media (min-width: 426px) and (max-width: 768px) {
  .contact-h {
    padding: 0;
  }

  .our-process-space {
    height: 50px;
    width: 0;
  }

  #carousel-1 {
    height: 190px;
  }

  #carousel-item {
    height: 150px;
  }
}

/* Laptop */
@media (min-width: 769px) and (max-width: 1024px) {
  .contact-h {
    padding: 0;
  }

  .our-process-space {
    height: 50px;
    width: 0;
  }

  #carousel-1 {
    height: 190px;
  }

  #carousel-item {
    height: 150px;
  }

  .pavathi-container {
    margin-left: 50px;
  }
}

/* Desktop */
@media (min-width: 1025px) and (max-width: 1440px) {
  .contact-h {
    padding: 0;
  }

  #our-product {
    margin-left: 130px;
  }

  #carousel-1 {
    height: 190px;
  }

  #carousel-item {
    height: 150px;
  }
}

/* 4k */
@media (min-width: 1441px) and (max-width: 2560px) {
  .contact-h {
    padding: 0;
  }

  #our-product {
    margin-left: 130px;
  }

  #carousel-1 {
    height: 190px;
  }

  #carousel-item {
    height: 150px;
  }
}

.contact-f {
  padding: 0 10%;
}

@media (max-width: 1100px) {
  .contact-f {
    padding: 0;
    margin: 0;
  }
}

.line-sale {
  padding: 0 100px 0 0;
}

@media (max-width: 1100px) {
  .line-sale {
    padding: 0;
    margin: 0;
  }
}

#myNav {
  padding: 40px 200px 0 200px;
}

@media (max-width: 1100px) {
  #myNav {
    padding: 0;
  }
}

.other-link {
  text-decoration: none;
  height: 10px;
  color: #009444;
}

.other-link:active,
.other-link:hover,
.other-link:link,
.other-link:visited {
  text-decoration: none;
  color: #009444;
}

/* Article list */

.article-list {
  padding: 40px 200px 0 200px;
}

@media (max-width: 1100px) {
  .article-list {
    padding: 0;
  }
}

.article-list {
  color: #313437;
  background-color: #fff;
}

.article-list p {
  color: #7d8285;
}

.article-list h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .article-list h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.article-list .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.article-list .intro p {
  margin-bottom: 0;
}

.article-list .articles {
  padding-bottom: 40px;
}

.article-list .item {
  padding-top: 50px;
  min-height: 425px;
  text-align: justify;
}

.article-list .item .name {
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  color: inherit;
}

.article-list .item .description {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 0;
}

.article-list .item .action {
  font-size: 24px;
  width: 24px;
  margin: 22px auto 0;
  line-height: 1;
  display: block;
  color: #4f86c3;
  opacity: 0.85;
  transition: opacity 0.2s;
  text-decoration: none;
}

.article-list .item .action:hover {
  opacity: 1;
}

/* Contact from clean */

.contact-clean {
  background: #f1f7fc;
  padding: 80px 0;
}

@media (max-width: 767px) {
  .contact-clean {
    padding: 20px 0;
  }
}

.contact-clean form {
  max-width: 480px;
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 4px;
  color: #505e6c;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .contact-clean form {
    padding: 30px;
  }
}

.contact-clean h2 {
  margin-top: 5px;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 36px;
  color: inherit;
}

.contact-clean form .form-control {
  background: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  outline: none;
  color: inherit;
  padding-left: 12px;
  height: 42px;
}

.contact-clean form .form-control,
.form-control-lg:focus {
  /*border: 1px solid #b2b2b2;*/
  border: 1.5px solid #009444;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.8);
}

.contact-clean form textarea.form-control {
  min-height: 100px;
  max-height: 260px;
  padding-top: 10px;
  resize: vertical;
}

.contact-clean form .btn {
  padding: 16px 32px;
  border: none;
  background: none;
  box-shadow: none;
  text-shadow: none;
  opacity: 0.9;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
  outline: none !important;
}

.contact-clean form .btn:hover {
  opacity: 1;
}

.contact-clean form .btn:active {
  transform: translateY(1px);
}

.contact-clean form .btn-primary {
  background-color: #055ada !important;
  margin-top: 15px;
  color: #fff;
}

/* Feature box */

.features-boxed {
  color: #313437;
  background-color: #eef4f7;
}

.features-boxed p {
  color: #7d8285;
}

.features-boxed h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .features-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.features-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

.features-boxed .intro p {
  margin-bottom: 0;
}

.features-boxed .features {
  padding: 50px 0;
}

.features-boxed .item {
  text-align: center;
}

.features-boxed .item .box {
  text-align: center;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
}

.features-boxed .item .icon {
  font-size: 60px;
  color: #1485ee;
  margin-top: 20px;
  margin-bottom: 35px;
}

.features-boxed .item .name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 0;
  color: inherit;
}

.features-boxed .item .description {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}

/* Feature clean */

.features-clean {
  color: #313437;
  background-color: #fff;
  padding-bottom: 30px;
}

@media (max-width: 767px) {
  .features-clean {
    padding-bottom: 10px;
  }
}

.features-clean p {
  color: #7d8285;
}

.features-clean h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}

@media (max-width: 767px) {
  .features-clean h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.features-clean .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 60px;
}

@media (max-width: 767px) {
  .features-clean .intro {
    margin-bottom: 40px;
  }
}

.features-clean .item {
  min-height: 100px;
  padding-left: 80px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .features-clean .item {
    min-height: 0;
  }
}

.features-clean .item .name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
  color: inherit;
}

.features-clean .item .description {
  font-size: 15px;
  margin-bottom: 0;
}

.features-clean .item .icon {
  font-size: 40px;
  color: #1485ee;
  float: left;
  margin-left: -65px;
}

/* Footer clean */

.footer-clean {
  padding: 50px 0;
  background-color: #fff;
  color: #4b4c4d;
}

.footer-clean h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-clean ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-clean ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-clean ul a:hover {
  opacity: 1;
}

.footer-clean .item.social {
  text-align: right;
}

@media (max-width: 767px) {
  .footer-clean .item {
    text-align: center;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .footer-clean .item.social {
    text-align: center;
  }
}

.footer-clean .item.social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-top: 22px;
  color: inherit;
  opacity: 0.75;
}

.footer-clean .item.social > a:hover {
  opacity: 0.9;
}

@media (max-width: 991px) {
  .footer-clean .item.social > a {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer-clean .item.social > a {
    margin-top: 10px;
  }
}

.footer-clean .copyright {
  margin-top: 14px;
  margin-bottom: 0;
  font-size: 13px;
  opacity: 0.6;
}

/* Highlight phone */

.highlight-phone {
  color: #313437;
  background-color: #eef4f7;
  padding: 0 50px;
  margin: 0 10px 0 150px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .highlight-phone {
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .highlight-phone {
    margin: 0;
  }
}

.highlight-phone p {
  color: #7d8285;
}

.highlight-phone h2 {
  font-weight: bold;
  margin-bottom: 35px;
  line-height: 1.5;
  padding-top: 0;
  margin-top: 0;
  color: inherit;
}

.highlight-phone .intro {
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 767px) {
  .highlight-phone .intro {
    max-width: none;
  }
}

.highlight-phone .intro p {
  margin-bottom: 35px;
}

.highlight-phone .intro .btn {
  padding: 16px 32px;
  border: none;
  background: none;
  box-shadow: none;
  text-shadow: none;
  opacity: 0.9;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
}

.highlight-phone .intro .btn:hover {
  opacity: 1;
}

.highlight-phone .intro .btn:active {
  transform: translateY(1px);
}

.highlight-phone .intro .btn-primary {
  background-color: #055ada;
  color: #fff;
}

.highlight-phone .phone-mockup {
  position: relative;
  max-width: 260px;
  margin-top: -30px;
  margin-bottom: -230px;
  transform: rotate(-15deg);
}

.highlight-phone .phone-mockup img.device {
  width: 100%;
  height: auto;
}

.highlight-phone .phone-mockup .screen {
  position: absolute;
  width: 94%;
  height: 91%;
  top: 1%;
  left: 3%;
  border: 1px solid #444;
  border-radius: 20px;
}

.highlight-phone .phone-mockup .screen:before {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 70%;
  height: 140%;
  top: -6%;
  right: -60%;
  transform: rotate(-19deg);
  opacity: 0.2;
}

/* Navigation clean */
.navigation-clean {
  background: #fff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .navigation-clean {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean .navbar-brand:hover {
  color: #222;
}

.navigation-clean .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean .navbar-toggler:hover,
.navigation-clean .navbar-toggler:focus {
  background: none;
}

.navigation-clean .navbar-toggler {
  color: #888;
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active,
.navigation-clean.navbar-light .navbar-nav .nav-link.active:focus,
.navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  color: #009444;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navigation-clean.navbar-light .navbar-nav .nav-link:focus,
.navigation-clean.navbar-light .navbar-nav .nav-link:hover {
  color: #009444 !important;
  background-color: transparent;
}

.navigation-clean .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: none;
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus,
.navigation-clean .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus,
.navigation-clean .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

/* register */

.register-photo {
  background: #f1f7fc;
  padding: 80px 0;
}

.register-photo .image-holder {
  display: table-cell;
  width: auto;
  background-size: cover;
}

.register-photo .form-container {
  display: table;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.register-photo form {
  display: table-cell;
  width: 400px;
  background-color: #ffffff;
  padding: 40px 60px;
  color: #505e6c;
}

@media (max-width: 991px) {
  .register-photo form {
    padding: 40px;
  }
}

.register-photo form h2 {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.register-photo form .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}

.register-photo form .form-check {
  font-size: 13px;
  line-height: 20px;
}

.register-photo form .btn-primary {
  background: #f4476b;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 35px;
  text-shadow: none;
  outline: none !important;
}

.register-photo form .btn-primary:hover,
.register-photo form .btn-primary:active {
  background: #eb3b60;
}

.register-photo form .btn-primary:active {
  transform: translateY(1px);
}

.register-photo form .already {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}
